.mainContainer {
    background-color: #FFFAF4;
    height: 100vh;
    width: 100%;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    margin: auto auto auto auto;
    width: 60%;
    padding-top: 10em;
}

.left {
    text-align: center;
    color: white;
    background-color: #516950;
    background-image: url('../../../assets/Contact-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 550px;
    width: 550px;
    border-radius: 20px 0 0 20px;
}

.left h2 {
    margin-top: 35px;
    font-size: 60px;
    text-shadow: #D4EAD0 3px 0px 5px;
}

.right {
    background-color: #D4EAD0;
    height: 550px;
    width: 550px;
    border-radius: 0 20px 20px 0;
    padding: 20px;
    padding-left: 100px;
    padding-right: 100px;
    color: #516950;
}

.formHeading {
    /* margin-top: 25px; */
    font-size: 30px;
    /* white-space: nowrap; */
    color: #516950;
}

.label {
    color: #516950;
    display: block;
    font-size: 1em;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;

    @media (max-width: 40em) {
        color: white;
        font-weight: 600;
    }
}

.socialBar {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 60%;
    margin-top: 436px;
    padding: 50px;
}

.socialBar a {
    color: white;
    text-decoration: none;
}

.socialBar a:hover {
    color: white;
}

.social {
    margin: 15px;
    /* padding: 20px; */
    height: 2.2em;
    width: 2.2em;
    background-color: #425641;
    border-radius: 5px;
    cursor: pointer;

}

.icon {
    font-size: 20px;
    /* margin-top: 10px; */
    /* align-items: center; */
    /* align-content: center;/ */
}

.youtube {
    /* color:rgb(219, 8, 8); */
    /* background-color: white; */
    /* border-radius: 18px; */
    /* border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-end-end-radius: 20px; */

}


.loader {
    background-color: rgba(0, 0, 0, 0.7);
    color: #516950;
    /* display: none; */
}

.hide {
    display: none;
}

@media only screen and (max-width: 800px) {
    .innerContainer {
        flex-direction: column;
    }

    .left {
        border-radius: 20px 20px 0 0;
    }

    .right {
        border-radius: 0 0 20px 20px;
    }

    .label{
        color: #516950;
    }

    .innerContainer {
        margin-left: 0 auto;
    }
}



.submit{
    width: 120px;
    background-color: #516950;
}


/* #################################### */


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');



#contact {
    /* background-color: #6a9ac4; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-box {
    width: clamp(100px, 90%, 1000px);
    margin: 120px 50px;
    display: flex;
    flex-wrap: wrap;
}

.contact-links,
.contact-form-wrapper {
    width: 50%;
    padding: 3% 5% 3% 5%;
}


.contact-links {
    background-color: #516950;
    background-image: url('../../../assets/Contact-bg.png');
    background-repeat: no-repeat;
    background-position: 0;
    background-size: contain;
   
    border-radius: 10px 0 0 10px;
}

.contact-form-wrapper {
    background-color: #D4EAD0;
    text-align: center;
    border-radius: 0 10px 10px 0;
}

@media only screen and (max-width: 800px) {

    .contact-links{
        display: none;
    }
    .contact-form-wrapper {
        width: 100%;
        
    }

    .contact-links {
        border-radius: 10px 10px 0 0;
    }

    .contact-form-wrapper {
        border-radius: 0 0 10px 10px;
    }
    .link{
        transform: translateY(-18px);
    }
}

@media only screen and (max-width: 400px) {
    .contact-box {
        width: 95%;
        margin: 8% 5%;
    }

    
}

h2 {
    /* font-family: 'Arimo', sans-serif;
    color: #fff;
    font-size: clamp(30px, 6vw, 60px);
    letter-spacing: 2px;
    text-align: center;
    transform: scale(.95, 1); */
}

.links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* padding-top: 50px; */
    margin-top: 430px;
    transform: translateY(30px);
    /* padding: 10px; */
    
}

.link {
    background-color: #425641;
    width: 2em;
    height: 2em;
    border-radius: 5px;
    justify-content: center;
    margin: 10px;
    padding-left: 6px;
    padding-top: 4px;
    cursor: pointer;
    color: #97c295;
}



img:hover {
    /* transform: scale(1.1, 1.1); */
}

img:active {
    /* transform: scale(1.1, 1.1); */
    /* filter:
        hue-rotate(220deg) drop-shadow(2px 4px 4px #222) sepia(0.3); */
}

.form-item {
    position: relative;
}

label,
input,
textarea {
    font-family: 'Poppins', sans-serif;
}

label {
    position: absolute;
    top: 10px;
    left: 2%;
    color: #999;
    font-size: clamp(14px, 1.5vw, 18px);
    pointer-events: none;
    user-select: none;
}

input,
textarea {
    width: 100%;
    outline: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 12px;
    font-size: clamp(15px, 1.5vw, 18px);
}

input:focus+label,
input:valid+label,
textarea:focus+label,
textarea:valid+label {
    font-size: clamp(13px, 1.3vw, 16px);
    color: #777;
    top: -20px;
    transition: all .225s ease;
}

.submit-btn {
    background-color: #fd917e;
    filter: drop-shadow(2px 2px 3px #0003);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: clamp(16px, 1.6vw, 18px);
    display: block;
    padding: 12px 20px;
    margin: 2px auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
}

.submit-btn:hover {
    transform: scale(1.1, 1.1);
}

.submit-btn:active {
    transform: scale(1.1, 1.1);
    filter: sepia(0.5);
}

@media only screen and (max-width: 800px) {
    h2 {
        font-size: clamp(40px, 10vw, 60px);
    }
}

@media only screen and (max-width: 400px) {
    h2 {
        font-size: clamp(30px, 12vw, 60px);
    }

    .links {
        padding-top: 30px;
    }

    img {
        /* width: 38px;
        height: 38px; */
    }
}
.modal-center{
    width: 100vw;
    
    padding-inline: 2%;
    text-align: center;
}

.modal{
    background-color: rgba(0, 0, 0, 0.7);;
}