
:root {
  --lightLightGreen: #D4EAD0;
  --lightDarkGreen: #516950;
  --lightLightOrange: #FF8458;
  --lightOffWhite: #FFFAF4;
  --lightGray: #FAF9F6;

  --lightBody: #fff;
  --lightTextg: #1a7038;
  --lightText: #202020;
  --lightButtonColorO: #fbca57;
  --lightButtonColor: #00401d;
  --lightBodyRgba: 255, 255, 255;
  --lightTextRgba: 32, 32, 32;
  --lightCarouselColor: #EEEDDE;

  --lightFontXS: 0.75em;
  --lightFontSM: 0.875em;
  --lightFontMD: 1em;
  --lightFontLG: 1.25em;
  --lightFontXL: 2em;
  --lightFontXXL: 3em;
  --lightFontXXXL: 4em;

  --lightFontButton: 0.875em;
  --lightNavHeight: 5rem;
}


:root {
  --darkBody: #202020;
  --darkTextg: #1a7038;
  --darkText: #202020;
  --darkButtonColor: #00401d;
  --darkButtonColorO: #fbca57;
  --darkBodyRgba: 32, 32, 32;
  --darkTextRgba: 255, 255, 255;
  --darkLeafColor: #fdfde3;
  --darkCarouselColor: #EEEDDE;

  --darkFontXS: 0.75em;
  --darkFontSM: 0.875em;
  --darkFontMD: 1em;
  --darkFontLG: 1.25em;
  --darkFontXL: 2em;
  --darkFontXXL: 3em;
  --darkFontXXXL: 4em;

  --darkFontButton: 0.875em;
  --darkNavHeight: 5rem;
}


.MainSection{
  background-color: var(--lightBody);
}
.ImageSection {
    top: 0;
    left: 0;
    /* height: 100vh; */
    overflow: hidden;
    position: relative;
  }
  
  .packageImage {
    position: relative;
    /* width: 100vw;
    height: 100vh; */
   
  }
  
  .packageContent {
    background-color: #d9d9d958;
    color: white;
    position: absolute;
    z-index: 1;
    width: 35%;
    top: 20%;
    padding: 2%;
    border-radius: 20px;
    left: 60%;
  }
  
  .packageContent h3 {
    text-align: center;
    font-size: var(  --lightFontXL);
    text-decoration: underline;
    padding-bottom: 25px;
  }
  
  .packageContent p {
    text-align: center;
    font-size: var(  --lightFontMD);
  }
  
  .package-list {
    display: flex;
    gap: 30px;
    margin-inline: 3%;
  }
  
  .package-item {
    padding: 20px;
    flex-direction: column;
  }
  
  .package-name {
   
    margin-bottom: 10px;
  }
  
  .package-image {
    max-width: 100%;
    height: auto;
    margin-left: 8%;
    margin-top: 28%;
  }
  .imageDataContner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
  }
  .package-name{
    
    text-align: center;
    font-size: 25px;
    padding-inline: 30px;
    line-height: 38px;
    margin-top: 20px;
    font-size: var(  --lightFontXL);
    font-weight: bold;
    color:  var(--lightTextg);
    
    

  }
  
  .class-list {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 30px;
  }
  


.BoxContentPackages {
    margin-top: 29%;
    position: relative;
    background-color:var( --lightLightGreen);
    width: 306px;
    height: 332px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--lightTextg);
    font-size: var(--lightFontM);
    font-weight: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.29);
    
  }
  
  .priceCircle {
    position: absolute;
    top: -60px;
    height: 100px;
    width: 100px;
    left: 50%; 
    transform: translateX(-50%);
    border-radius: 50%;
    border: 5px solid var(--lightLightGreen);
    background-color: rgb(247, 247, 247);
    font-size: 28px;
    
  }
  
  .priceCircle h5 {
    position: absolute;
    text-align: center;
    font-size: 18px;
    top: 38%;
    left: 13%;
  }
  
  .packageType {
    padding: 30px;
    text-align: center; 
    margin-top: 15%;
    font-size: 22px;
    font-weight: 600;
   

  }
  

  @media screen and (max-width: 1200px) {
    .packageContent {
      width: 45%;
      left: 50%;
      transform: translateX(-50%);
      
    }
    .packageImage{
        object-fit: cover;
    }
    .package-list {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
  }
  
  @media screen and (max-width: 873px) {
    .packageContent {
      width: 60%;
      left: 50%;
      transform: translateX(-50%);
      
    }
    .packageImage{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .package-image{
        object-fit: cover;
        max-width: 100%;
        height: auto;
        /* margin-left: 8%; */
        margin-top: 28%;
        width: 70%;
    }
    .package-list {
      display: flex;
      flex-direction: column;
     
    }
   .BoxContentPackages {
    width: 100%;
    height: 394px;
    font-size: 14px;
    
    }
    .packageType {
       
        font-size: 16px;
        font-weight: 600;
        
      }
}
  
  @media screen and (max-width: 600px) {
    .packageContent {
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      
    }
  
    .ImageSection {
      /* display: flex;
      justify-content: start;
      align-items: start; */
      /* object-fit: fill; */
      height: 70vh;

    }
    .packageImage{
     
     
     
        
    }
  
    .package-list,
    .class-list {
      display: flex;
      flex-direction: column;
    }
  
    .package-item,
    .class-item {
     
      padding: 20px;
      
    }
    .BoxContentPackages {
        width: 306px;
        height: 332px;
        font-size: 16px;
        
        }
  }

